<template>
    <ul class="owner-list">
        <template v-for="(item, idx) in (data || ownerStore.ownerList)" :key="item.id">
            <li class="owner-content pointer" :class="[ownerStore.ownerId == item.id ? 'active' : '']"
                @click="ownerStore.selectOwner(item, idx)">
                {{ item.name }}
            </li>
            <template v-if="item.children.length > 0">
                <owner-tree :data="item.children" style="margin-left:1rem" />
            </template>
        </template>
    </ul>
</template>

<script>

export default {
    name: 'owner-tree'
}
</script>

<script setup>
import { useOwnerStore } from '~~/store/owner';

const ownerStore = useOwnerStore();
const { data } = defineProps({
    data: Array,
})
</script>
<template>
    <section class="nav-menu">
        <template v-for="item in menuList" :key="item.key">
            <div class="nav-menu-text flex-ver-center pointer" :class="[
                route.meta.menu == item.key ? 'active' : ''
            ]" @click="fn.goPage(item.path)">
                <svg-icon :icon-class="item.icon"></svg-icon>
                <span>{{ item.text }}</span>
            </div>
        </template>
    </section>
</template>
<style lang="scss" scoped>
    .nav-menu-text {
        font-size: 1.4rem;
    }
</style>
<script setup>
import { useUserStore } from '~/store/user';

const userStore = useUserStore();
const menuList = computed(() => {
    return userStore.menu || [];
});
const router = useRouter();
const route = useRoute();
const { data } = defineProps({
    data: Object,
})
const fn = {
    goPage(url) {
        router.push(url);
    }
}
</script>
<style lang="scss" scoped>
    .nav-menu {
        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        &-text {
            margin: 0 10px;
            padding: 5px 8px;
            color: var(--primary-color-light);
            border-radius: 6px;
            &.active {
                font-weight: bolder;
                background-color: var(--primary-background-color-light-2);
            }
        }
    }
</style>


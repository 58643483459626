const day = {
    6: '六',
    5: '五',
    4: '四',
    3: '三',
    2: '二',
    1: '一',
    0: '日',
}
export default day;

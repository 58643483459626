<template>
    <section v-if="showDialog">
        <el-drawer
            v-model="showDialog"
            modal-class="drawer-modal-transparent"
            :append-to-body="appendToBody"
            :destroy-on-close="destroyOnClose"
            :modal="modal"
            :title="title"
            :with-header="Boolean(title)"
            :size="width"
            :direction="direction"
            @closed="closeEvent"
        >
            <slot />
        </el-drawer>
    </section>
</template>

<script >
export default {
    props: {
        direction: {
            type: String,
            default: 'rtl',
        },
        show: {
            type: Boolean,
            default: false,
        },
        modal: {
            type: Boolean,
            default: true,
        },
        appendToBody: {
            type: Boolean,
            default: true,
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        destroyOnClose: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "40%",
        },
    },

    data() {
        return {
            showDialog: false,
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
    },
    methods: {
        closeEvent() {
            this.showDialog = false;
            this.$emit("close");
        },
    },
};
</script>
